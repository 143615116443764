import { gql } from "apollo-boost";

export const reportMixin = {
  methods: {
    getVeneueReports(options) {
      const getReportList = gql`
        query venueReportsList($options: ListReportedVenue!){
          venueReportsList(options: $options){
                data{
                    _id
                    report
                    reportedBy{
                      _id
                      firstName
                      lastName
                      profileImage
                      username
                    }
                    name
                    address
                    country
                    reportedAt
                    placeId
                }
                pagination{
                  total
                  totalPage
                  page
                }
            }
          }
        `;
      return this.$apollo.query({
        query: getReportList,
        variables: {
          options
        }
      }).then(response => {
        return response.data.venueReportsList
      });
    },
    getUserReports(options) {
      const getReportList = gql`
        query userReportsList($options: DatatableInput!){
          userReportsList(options: $options){
                data{
                    _id
                    report
                    reportedBy{
                      _id
                      fullName
                      profileImage
                      username
                    }
                    reportedUser{
                      _id
                      fullName
                      profileImage
                      username
                    }
                    reportedAt
                }
                pagination{
                  total
                  totalPage
                  page
                }
            }
          }
        `;
      return this.$apollo.query({
        query: getReportList,
        variables: {
          options
        }
      }).then(response => {
        return response.data.userReportsList
      });
    },
    getVenueDetail(venueId) {
      const venueData = gql`
        query venueDetail($venueId: ID!){
          venueDetail(venueId: $venueId){
            data{
              _id
              photo
              name
              category{
                name
                slug
                icon
              }
              location{
                country
                state
                city
                address
              }
              contact
            }
          }
        }
      `;
      return this.$apollo.query({
        query: venueData,
        variables: {
          venueId
        }
      }).then(response => {
        return response.data.venueDetail.data
      });
    }
  }
}
