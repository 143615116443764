<template>
  <vx-card style="padding-left: 10px">
    <!-- <vs-label style="font-weight: bold"> -->
    <!-- </vs-label> -->
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="reports"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          ></div>
          <v-select
            v-model="dataTableParams.limit"
            class="mr-4"
            :options="limitOptions"
            :clearable="false"
          />
        </div>

        <template slot="thead">
          <vs-th sort-key="report">Report</vs-th>
          <vs-th sort-key="reportedBy">Reported By</vs-th>
          <vs-th sort-key="name">Name</vs-th>
          <vs-th sort-key="address">Address</vs-th>
          <vs-th sort-key="country">Country</vs-th>
          <vs-th sort-key="reportedAt">Reported At</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].report">{{
              data[indextr].report
            }}</vs-td>
            <vs-td :data="data[indextr].reportedBy._id">
              <div class="flex items-center">
                <vs-avatar
                  class="m-0"
                  :src="data[indextr].reportedBy.profileImage"
                  size="35px"
                  @click="
                    $router.push({
                      path: `user-management/view/${data[indextr].reportedBy._id}`,
                    })
                  "
                />
                <div
                  class="leading-tight ml-3"
                  @click="
                    $router.push({
                      path: `user-management/view/${data[indextr].reportedBy._id}`,
                    })
                  "
                >
                  <p class="feed-author font-semibold">
                    {{
                      data[indextr].firstName !== null
                        ? data[indextr].reportedBy.firstName + " " + data[indextr].reportedBy.lastName
                        : "N/A"
                    }}
                  </p>
                  <span class="flex items-center"
                    ><small>{{
                      data[indextr].reportedBy.username === ""
                        ? ""
                        : data[indextr].reportedBy.username
                    }}</small></span
                  >
                </div>
              </div>
            </vs-td>
            <!-- <vs-td :data="data[indextr].lastName">{{ data[indextr].lastName }}</vs-td> -->

            <vs-td :data="data[indextr].name">
              <div
                class="leading-tight ml-3"
                @click="
                    $router.push({
                      path: `venue-management/view/${data[indextr].placeId}`,
                    })
                  "
              >
                <span class="feed-author font-semibold">
                  {{
                    data[indextr].name
                  }}
                </span>
              </div>
            </vs-td>
                        <vs-td :data="data[indextr].address">{{
              data[indextr].address
            }}</vs-td>
                        <vs-td :data="data[indextr].country">{{
              data[indextr].country
            }}</vs-td>
            <vs-td :data="data[indextr].reportedAt">{{
              moment(data[indextr].reportedAt).format("DD/MM/YYYY")
            }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2"
        >{{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
        }}
        -
        {{
          pagination.total - dataTableParams.page * dataTableParams.limit > 0
            ? dataTableParams.page * dataTableParams.limit
            : pagination.total
        }}
        of {{ pagination.total }}</span
      >
      <div
        class="vs-row"
        style="justify-content: space-between; display: flex; width: 100%"
      >
      <div class="vs-col vs-pagination--mb vs-xs-12 vs-sm-12 vs-lg-6" style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;"></div>
        <paginate
          :page-count="pagination.totalPage"
          :click-handler="handleChangePage"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
  </vx-card>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import moment from "moment";
import { reportMixin } from "../../mixins/reportMixin";
import Paginate from "vuejs-paginate";
Vue.use(Paginate);

export default {
  mixins: [reportMixin],
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "reportedAt",
        dir: "desc",
        page: 1,
        limit: 30,
      },
      reports: [],
      pagination: {
        total: 0,
        totalPage: 1,
        page: 1,
      },
      limitOptions: [30, 50, 100, 150],
      selectedUser: {},
      selectedIndex: 0,
      headerVal: [],
    };
  },
  methods: {
    moment(date) {
      return moment(date);
    },
    getReportList() {
      let self = this;
      this.getVeneueReports(self.dataTableParams).then((res) => {
        self.reports = res.data;
        self.pagination = res.pagination;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getReportList();
    },
    handleChangePage(page) {
      console.log("page change", page);
      this.dataTableParams.page = page;
      this.getReportList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getReportList();
    }

  },
  computed: {
    totalPage: function () {
      return this.pagination.totalPage;
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      console.log("page change watch");
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getReportList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getReportList();
      }
    },
  },
  created() {
    this.getReportList();
  },
};
</script>
